<script>
import permissionsService from '@/services/permissions.service'

export default {
  name: 'Permissions',
	data() {
		return {
      headerFields: [
        "__slot:checkboxes",
        {
          name: "id",
          label: "ID",
        },
        {
          name: "name",
          label: this.$t('title'),
        },
        "__slot:actions:edit",
        "__slot:actions:delete"
      ],
		}
  },

  computed: {
    items() {
      return this.$store.state.permissions.permissions
    }
  },

	methods: {
    remove(id) {
      permissionsService.remove(id)
        .then(success => {
          this.$notify({
              message: success.data.message,
              icon: "far fa-bell",
              horizontalAlign: 'right',
              verticalAlign: 'top',
              type: 'success',
            })
          this.$store.dispatch('permissions/getAllPermissions')
        })
    }
	},
}
</script>

<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="form-group col-12 text-right">
          <router-link :to="{ name: 'singlePermission', params: { id: 'new' } }">
            <BaseButton 
              type="green"
              gradient
            >
              Create Permission
            </BaseButton>
          </router-link>
        </div>
      </div>
    </div>
    <div class="card-body">
      <BaseListTable
        :items="items"
        :header-fields="headerFields"
        type="permissions"
        @delete="remove"
      />
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

</style>
